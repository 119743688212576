import Head from 'next/head'
import {
  Hero,
  CommonIssuesTreated,
  TypesOfTherapyOffered,
  TenasBio,
  FAQ,
} from 'sections/home'

export default function Home() {
  return (
    <>
      <Head>
        <title>Online Therapy | Sunridge Counseling</title>
        <meta
          name="description"
          content="Sunridge Counseling - Your journey towards healing starts here - Online therapy in Arizona, New Mexico, and Pennsylvania"
        />
        <link rel="canonical" href="https://sunridgecounseling.com/" />
      </Head>
      <Hero />
      <CommonIssuesTreated />
      <TypesOfTherapyOffered />
      <TenasBio />
      <FAQ />
    </>
  )
}
